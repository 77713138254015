import './styles.scss';

import { Col, Divider, Form, Input, Row } from 'antd';
import clsx from 'clsx';
import B2becButton from 'components/B2becButton';
import { B2becButtonBack } from 'components/CustomButton/B2becButtonIcon';
import { DeliveryAddress } from 'components/DisplayAddress';
import {
  ACTION_STATUS,
  SERVICE_CASE_PRIORITY,
  SERVICE_ENTRY,
  SERVICE_TYPE,
} from 'libs/constants';
import { mergeIntoStringWithSeparator } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUserId } from 'store/selectors/authenticationSelectors';
import { selectRegions } from 'store/selectors/configSelector';
import {
  getCustomerNumber,
  getDeliveryAddresses,
} from 'store/selectors/customerSelector';
import {
  getSelectedMachineData,
  selectIsSubmitServiceLoading,
  selectSubmitServiceStatus,
} from 'store/selectors/serviceCaseSelector';
import { selectUserLanguage } from 'store/selectors/userSelector';
import { createServiceCase } from 'store/slices/serviceCaseSlice';

import useDeviceDetect from '../../../../hooks/useDeviceDetect';
import ContactInformation from './components/ContactInformation';
import DamagedPicture from './components/DamagedPicture';
import FailureDescription from './components/FailureDescription';
import SelectedMachine from './components/SelectedMachines';
import SelectedService from './components/SelectedService';
import ServiceBlockWrapper from './components/ServiceBlockWrapper';
import WhatHappensNext from './components/WhatHappensNext';

const ServiceOverviewStep = ({ currentTab, handleChangeTab, form }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const tLabel = (key) => t(`service.serviceOverview.label.${key}`);

  const deliveryAddresses = useSelector(getDeliveryAddresses);
  const isLoadingSubmitServiceCase = useSelector(selectIsSubmitServiceLoading);
  const submitServiceStatus = useSelector(selectSubmitServiceStatus);
  const selectedMachineData = useSelector(getSelectedMachineData);
  const currentUserCustomerNumber = useSelector(getCustomerNumber);
  const userId = useSelector(selectUserId);
  const language = useSelector(selectUserLanguage);

  const {
    serviceEntry,
    id: machineId,
    name: machineName,
    serialNumber,
  } = selectedMachineData;

  const [furtherNotes, setFurtherNotes] = useState('');
  const [failureDescriptionData, setFailureDescriptionData] = useState([]);
  const regions = useSelector(selectRegions);

  const selectedFAQs = form.getFieldValue('failureDescriptions');

  const failureDescriptionNotes = form.getFieldValue('failureDescriptionNotes');

  const selectedDesiredService = useMemo(
    () =>
      serviceEntry !== SERVICE_ENTRY.MACHINE
        ? serviceEntry
        : form.getFieldValue('serviceType'),
    [form, serviceEntry]
  );

  const partnerNumber = currentUserCustomerNumber;
  const deliveryAddress = form.getFieldValue('deliveryAddress');
  const damagedImages = form.getFieldValue('damagedImages');

  const { isMobile } = useDeviceDetect();

  const contactPersonFirstName = form.getFieldValue('contactPersonFirstName');
  const contactPersonLastName = form.getFieldValue('contactPersonLastName');
  const contactPersonEmail = form.getFieldValue('contactEmail');
  const contactPersonPhoneNumber = form.getFieldValue('contactNumber');

  const contactInformation = {
    firstName: contactPersonFirstName,
    lastName: contactPersonLastName,
    email: contactPersonEmail,
    phoneNumber: contactPersonPhoneNumber,
  };

  useEffect(() => {
    if (
      !isLoadingSubmitServiceCase &&
      submitServiceStatus === ACTION_STATUS.SUCCESS
    ) {
      history.push(linkGenerator('/request-confirmation'));
    }
  }, [history, serviceEntry, submitServiceStatus, isLoadingSubmitServiceCase]);

  useEffect(() => {
    let faqs = [];
    if (selectedFAQs && selectedFAQs.length > 0) {
      faqs = selectedFAQs;
    }

    if (failureDescriptionNotes) {
      faqs.push(failureDescriptionNotes);
    }
    setFailureDescriptionData(faqs);
  }, [selectedFAQs, failureDescriptionNotes, setFailureDescriptionData]);

  const requestButtonKey = useMemo(() => {
    switch (serviceEntry) {
      case SERVICE_ENTRY.REPAIR:
        return 'requestServiceRepair';
      case SERVICE_ENTRY.MAINTENANCE:
        return 'requestServiceMaintenance';
      case SERVICE_ENTRY.MACHINE:
        return 'requestMachineService';
      default:
        return '';
    }
  }, [serviceEntry]);

  const selectedDeliveryAddress = useMemo(() => {
    if (deliveryAddress?.street) {
      // select new address data
      return deliveryAddress;
    }
    return (
      partnerNumber &&
      deliveryAddresses.find((item) => item?.partnerNumber === partnerNumber)
    );
  }, [partnerNumber, deliveryAddress, deliveryAddresses]);

  const translatedSelectedServiceType = useMemo(() => {
    if (!selectedDesiredService) return '';
    return t(`service.serviceType.${selectedDesiredService.toLowerCase()}`);
  }, [t, selectedDesiredService]);

  const mappingSubmitServiceValue = useMemo(() => {
    const images = (damagedImages || []).map(({ fileName, imageBase64 }) => ({
      fileName,
      imageBase64,
    }));

    const { street, street4, houseNumber } = selectedDeliveryAddress;
    const mergeIntoString = mergeIntoStringWithSeparator(', ');

    const customerAddress = mergeIntoString(street, houseNumber, street4);
    const failureDescriptions = mergeIntoString(failureDescriptionData);

    const problemDescription =
      serviceEntry === SERVICE_ENTRY.MAINTENANCE ||
      selectedDesiredService === SERVICE_TYPE.MAINTENANCE
        ? translatedSelectedServiceType
        : `${translatedSelectedServiceType}: ${failureDescriptions}`;

    return {
      contactPersonFirstName,
      contactPersonLastName,
      customerNumber: partnerNumber,
      title: 'New Service case created (B2B)',
      problemDescription,
      images,
      casePriority: SERVICE_CASE_PRIORITY.NORMAL,
      countryCode: selectedDeliveryAddress?.country,
      customerFirstName: selectedDeliveryAddress?.name || '',
      customerLastName: selectedDeliveryAddress?.name2 || '',
      customerCompanyName: selectedDeliveryAddress?.name,
      customerAddress,
      customerNote: selectedDeliveryAddress?.extraText,
      customerCity: selectedDeliveryAddress?.city,
      customerRegion: selectedDeliveryAddress?.region,
      customerZipCode: selectedDeliveryAddress?.zip,
      materialNumber: machineId,
      materialName: machineName,
      serialNumber,
      contactEmail: contactPersonEmail,
      caseType: selectedDesiredService,
      isKaercher: true,
      furtherNotes,
      contactPersonPhoneNumber,
      language,
      userId,
    };
  }, [
    translatedSelectedServiceType,
    contactPersonPhoneNumber,
    selectedDeliveryAddress,
    failureDescriptionData,
    selectedDesiredService,
    contactPersonFirstName,
    contactPersonLastName,
    contactPersonEmail,
    partnerNumber,
    damagedImages,
    serialNumber,
    serviceEntry,
    furtherNotes,
    machineName,
    machineId,
    language,
    userId,
  ]);

  const onNotesChangeHandler = (e) => {
    setFurtherNotes(e?.target?.value);
  };

  const onRequestServiceHandler = useCallback(() => {
    if (
      mappingSubmitServiceValue.customerRegion &&
      regions[mappingSubmitServiceValue.customerRegion]
    ) {
      mappingSubmitServiceValue.customerRegion =
        regions[mappingSubmitServiceValue.customerRegion].label;
    }

    dispatch(createServiceCase(mappingSubmitServiceValue));
  }, [dispatch, mappingSubmitServiceValue, regions]);

  return (
    <div className="service-review">
      <Row gutter={[24, 35]}>
        <Col lg={16}>
          <Row gutter={[24, 35]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <ServiceBlockWrapper title={tLabel('machineSelected')}>
                <SelectedMachine data={selectedMachineData} />
              </ServiceBlockWrapper>
            </Col>
            <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 0 }}>
              <ServiceBlockWrapper title={tLabel('contactInformation')}>
                <ContactInformation data={contactInformation} />
              </ServiceBlockWrapper>
            </Col>
            <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 0 }}>
              <ServiceBlockWrapper title={tLabel('serviceSelected')}>
                <SelectedService serviceType={translatedSelectedServiceType} />
              </ServiceBlockWrapper>
            </Col>
            <Col xs={{ span: 24, order: 3 }} md={{ span: 12 }}>
              <ServiceBlockWrapper title={tLabel('deliveryAddress')}>
                <DeliveryAddress data={selectedDeliveryAddress} />
              </ServiceBlockWrapper>
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 12, push: 12 }}
          lg={{ span: 8, push: 0 }}
        >
          <div
            className={clsx({
              'container-full-width-page': isMobile,
            })}
          >
            <div className="what-happens-next">
              <ServiceBlockWrapper title={tLabel('whatHappensNext')}>
                <WhatHappensNext />
              </ServiceBlockWrapper>
            </div>
          </div>
        </Col>
      </Row>
      {/* NOTED :
        if entry point is from machine or repair
        user has to select the service including the FAQs as optional field
        Hence, we only render this FAQs overview section for machine and repair entries
      */}
      {serviceEntry !== SERVICE_ENTRY.MAINTENANCE && (
        <Row gutter={[24, 35]}>
          <Divider />
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <ServiceBlockWrapper title={tLabel('failureDescription')}>
              <FailureDescription data={failureDescriptionData || []} />
            </ServiceBlockWrapper>
          </Col>
          <Col
            xs={{ span: 24, order: 2 }}
            md={{ span: 12, order: 0 }}
            lg={{ span: 8 }}
          >
            <ServiceBlockWrapper title={tLabel('damagedPictures')}>
              <DamagedPicture data={damagedImages} />
            </ServiceBlockWrapper>
          </Col>
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 12 }}>
            <Form.Item
              className="custom-form-item"
              form={form}
              name="serviceFurtherNotes"
              label={tLabel('furtherNotes')}
            >
              <Input.TextArea
                style={{ resize: 'none' }}
                onChange={onNotesChangeHandler}
                placeholder={t(
                  'service.serviceOverview.placeHolder.furtherNotes'
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Divider />
      <Row gutter={[24, 24]}>
        <Col
          xs={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          className="service-overview__back-button"
        >
          <B2becButtonBack
            type="ghost"
            block
            onClick={() => {
              handleChangeTab(+currentTab - 1);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            {t('service.serviceOverview.button.backToAddressDate')}
          </B2becButtonBack>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8, push: 8 }}
          className="service-overview__request-button"
        >
          <B2becButton
            block
            onClick={onRequestServiceHandler}
            loading={isLoadingSubmitServiceCase}
            disabled={isLoadingSubmitServiceCase}
          >
            {t(`service.serviceOverview.button.${requestButtonKey}`)}
          </B2becButton>
        </Col>
      </Row>
    </div>
  );
};

ServiceOverviewStep.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
  }).isRequired,
  currentTab: PropTypes.number.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
};

export default ServiceOverviewStep;
